import React, { useEffect, useState } from "react";

import _ from "lodash";
import { useSelector } from "react-redux";

import {
  fetchItemsForListAction,
  fetchListOptionsAction,
} from "containers/lists/redux/actions";
import { allListsSelector } from "containers/lists/redux/selectors";
import { formatFilterForBackend } from "containers/lists/utils";
import { getItemName } from "containers/lists/utils/getItemLabel";
import { useActions } from "hooks";
import { IOption } from "model/application/components";

import { IFilter } from "../../../../model/application/Filter";
import BaseMultipleChoiceOnOptions from "./BaseMultipleChoiceOnOptions";

const ITEMS_LIMIT = 20;
/**
 * A ready to go MCOL filter on a list built on top of BaseMultipleChoiceOnOptions
 * since list filters are used often this saves us the trouble
 */
export interface IMultipleChoiceOnListFilterProps {
  filter: IFilter;
  onChangeFilter: (name: string, value: any) => any;
  searchDebounceDuration?: number;
  listId: string;
}

const MultipleChoiceOnListFilter: React.FC<
  IMultipleChoiceOnListFilterProps
> = ({ filter, onChangeFilter, listId, searchDebounceDuration }) => {
  const allLists = useSelector(allListsSelector);
  const list = allLists.find((l) => l.id === listId);
  const [fetchListOptions, fetchItemsForList] = useActions([
    fetchListOptionsAction,
    fetchItemsForListAction,
  ]);
  const [initialOptions, setInitialOptions] = useState<
    IOption<string>[] | undefined
  >(undefined);
  const [options, setOptions] = useState<IOption<string>[] | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  async function handleSearch(searchTerm: string) {
    setLoading(true);
    if (!_.isEmpty(searchTerm)) {
      if (!listId) {
        throw new Error(
          "ListId is required for MultipleChoiceOnListFilter's search"
        );
      }
      const newOptions: any = await fetchListOptions(
        listId,
        searchTerm,
        ITEMS_LIMIT
      );
      setOptions(formatOptions(newOptions));
    }
    setSearchTerm(searchTerm);
    setLoading(false);
  }

  function getFilterOptions() {
    const opts = options || filter.options || [];
    if (_.isEmpty(searchTerm)) {
      return _.uniqBy(_.concat(opts, initialOptions), "key");
    }
    return opts;
  }

  const opt = getFilterOptions();
  const preparedFilter: IFilter = {
    ...filter,
    onSearch: handleSearch,
    options: formatOptions(opt),
  };
  useEffect(() => {
    async function updateInitialOptions() {
      setLoading(true);
      if (list && !_.isEmpty(list.items)) {
        const activeItems = list.items.filter((item) => item._active);
        setInitialOptions(formatOptions(activeItems));
        setLoading(false);
        return;
      }
      const listItems = await fetchItemsForList(
        listId,
        {
          ...formatFilterForBackend(list ? list.query : {}),
          order: "asc",
          order_by: "_displayed_name",
          limit: 20,
        },
        {},
        list ? list.query : {}
      );
      setInitialOptions(formatOptions(listItems?.items));
      setLoading(false);
    }
    if (_.isEmpty(searchTerm) && initialOptions === undefined) {
      updateInitialOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOptions, listId, searchTerm]);

  return (
    <BaseMultipleChoiceOnOptions
      filter={preparedFilter}
      onChangeFilter={onChangeFilter}
      searchDebounceDuration={searchDebounceDuration}
      loading={loading}
    />
  );
};
export default MultipleChoiceOnListFilter;

function formatOptions(options: any): IOption<string>[] {
  return _.compact(
    _.map(options, (opt) => {
      if (!opt) return;
      const key = opt["id"] || opt["key"] || opt["_id"];
      const label = opt["name"] || opt["label"] || getItemName(opt);
      return { key, label };
    })
  );
}
