import _ from "lodash";

import { ACTION_OUTSIDE_THE_ACTION_BAR } from "components/Card/CustomCardTable";
import { getItemName } from "containers/lists/utils/getItemLabel";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { IList } from "model/entities/List";
import { IQuestion, IQuestionnaire } from "model/entities/Workflow";
import { selectContainingConditions } from "utils/expressions";
import formatExpression, {
  replaceMetaExpressions,
  replaceOperators,
} from "utils/expressions/formatExpression";

export const returnActions = (actions: ITableAction[]) => {
  const actionsCorrected = _.map(actions, (action) => {
    if (action.disabled == null) {
      action.disabled = () => false;
    }
    return action;
  });

  const createAction = _.find(actionsCorrected, {
    actionType: ACTION_TYPE.CREATE,
  });

  const actionsInActionBar = actionsCorrected.filter(
    (action) => !ACTION_OUTSIDE_THE_ACTION_BAR.includes(action.actionType)
  );
  const actionsInTable = _.filter(actionsCorrected, (action) =>
    _.includes(ACTION_OUTSIDE_THE_ACTION_BAR, action.actionType)
  );

  const clickableAction = _.find(actions, (el) => el.label === "clickable");

  const editAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.EDIT
  );
  const deleteAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.DELETE
  );
  const archiveAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.ARCHIVE
  );
  const restoreAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESTORE
  );
  const restoreVersionAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESTORE_VERSION
  );
  const viewAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.DETAIL
  );

  const stopAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.STOP
  );
  const resumeAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESUME
  );
  const replicateAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.REPLICATE
  );
  const extraActions = actionsInTable.filter(
    (action) => action.actionType === ACTION_TYPE.EXTRA_ACTION
  );
  const licenseAction = actionsInActionBar.find(
    (action) => action.actionType === ACTION_TYPE.LICENSE
  );
  const unlicenseAction = actionsInActionBar.find(
    (action) => action.actionType === ACTION_TYPE.UNLICENSE
  );

  return {
    createAction,
    editAction,
    deleteAction,
    archiveAction,
    restoreAction,
    restoreVersionAction,
    viewAction,
    stopAction,
    resumeAction,
    replicateAction,
    extraActions,
    licenseAction,
    unlicenseAction,
    clickableAction,
  };
};

export const getFormattedExpression = (
  conditions: any[],
  lang: TLang,
  metaExpressions: any
) => {
  const containingOperations = selectContainingConditions(conditions);
  const mainCondition = containingOperations[0];

  let formattedExpression = formatExpression({
    expression: mainCondition,
    expressionsArray: conditions,
  });

  formattedExpression = replaceOperators({
    formattedExpression,
    lang,
  });

  formattedExpression = replaceMetaExpressions({
    formattedExpression,
    metaExpressions,
  });

  return formattedExpression;
};

export const EditQuestionWithHisTag = (
  questionnaire: IQuestionnaire | IQuestion[],
  newQuestion: IQuestion
): IQuestion[] => {
  const questions = questionnaire["questions"]
    ? questionnaire["questions"]
    : questionnaire;

  for (let index = 0; index < questions.length; index++) {
    if (questions[index].tag === newQuestion.tag) {
      questions[index] = newQuestion;
    } else {
      if (questions[index].matrix) {
        EditQuestionWithHisTag(
          questions[index].matrix?.typed_questions,
          newQuestion
        );
      }
    }
  }
  return questions;
};
/**
 * Converts an items array from a IList type to a IOption type
 * @param list a list of type Ilist
 * @returns an array of options
 */
export const getOptionsFromList = (list?: IList): IOption<string>[] => {
  let formatted: IOption<string>[] = [];
  if (list) {
    formatted = _.map(
      _.filter(list.items, (item) => item._active),
      (item, index) => ({
        key: item._id,
        label: getItemName(item),
        index: index,
      })
    );
  }
  return formatted;
};

export const removeQuestionByTag = (
  questionnaire: IQuestionnaire | IQuestion[],
  tag: string
): IQuestion[] => {
  const questions = questionnaire["questions"]
    ? questionnaire["questions"]
    : questionnaire;

  for (let index = 0; index < questions.length; index++) {
    if (questions[index].tag === tag) {
      questions.splice(index, 1);
    } else {
      if (questions[index].matrix) {
        removeQuestionByTag(questions[index].matrix?.typed_questions, tag);
      }
    }
  }
  return questions;
};
