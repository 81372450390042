import { Suspense, useEffect, useState } from "react";

import _ from "lodash";
import { TLogger } from "log/FieldProLogger";
import qs from "qs";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import MainLayout from "components/Layout/MainLayout";
import NotFound from "components/NotFound/NotFound";
import ContentLoader from "components/Progress/ContentLoader";
import FullScreenLoader from "components/Progress/FullScreenLoader";
import PictureSkeletonLoader from "components/Progress/PictureSkeletonLoader";
import TableSkeletonLoader from "components/Progress/TableSkeletonLoader";
import PrivateRoute from "components/Route/PrivateRoute";
import ProtectedComponent from "components/Route/ProtectedComponent";
import {
  logoutAction,
  setActionsAvailableAction,
} from "containers/authentication/redux/actions";
import { getActionsAvailableSelector } from "containers/authentication/redux/selector";
import CalendarViewHandler from "containers/calendar/CalendarViewHandler";
import {
  changeSelectedClientAction,
  fetchAllClientsAction,
} from "containers/clients/redux/actions";
import ItemQRCodeDialog from "containers/lists/subcategories/items/ItemQRCodeDialog";
import useTranslations from "hooks/useTranslations";
import { getActionsAvailable } from "model/application/ActionCode";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import {
  APP_PATHS,
  DOCUMENTS_ROUTE,
  HOME_ROUTE,
  SCANNER_ROUTE,
  USERS_ROUTE,
} from "model/constants/routes";
import { IClient } from "model/entities/Client";
import { IWorkflow } from "model/entities/Workflow";

import { useActionsLegacy } from "../../hooks/useActions";
import AppBreadcrumbs from "./AppBreadcrumbs";
import ClientExpiredOverlay from "./ClientExpiredOverlay";
import {
  ClientsContainer,
  DashboardContainer,
  DocumentsContainer,
  EnvironmentContainer,
  ErpContainer,
  HomeContainer,
  ListsContainer,
  PanoramaContainer,
  PicturesContainer,
  PlacesContainer,
  TeamsContainer,
  UserMgtContainer,
  WorkflowsContainer,
} from "./pages";
import UpdateMonitor from "./UpdateMonitor";
import { isFullWidth } from "./utils/isFullWidth";

interface IAuthenticatedApp {
  client?: IClient;
  cookies: unknown[]; // TODO: verifiy and refacto this
  logger: TLogger;
}

const AuthenticatedApp = ({ client, cookies, logger }: IAuthenticatedApp) => {
  const route = location.pathname; // TODO;

  const [_lastUpdateTimestamp, setLastUpdateTimestamp] = useState<number>(); // TODO: refresh clients after 1h
  const [loading, setLoading] = useState(true);
  const lang = useTranslations();

  const actionsAvailable = useSelector(getActionsAvailableSelector);

  const setActionsAvailable = useActionsLegacy(setActionsAvailableAction);
  const handleLogout = useActionsLegacy(logoutAction);
  const fetchAllClients = useActionsLegacy(fetchAllClientsAction);
  const changeSelectedClient = useActionsLegacy(changeSelectedClientAction);

  const { access_right_profiles, profile } = client || {};

  // TODO: this does not fetch full selected client anymore /!\
  const fetchClientAndRefreshAll = () => {
    setLoading(true);

    fetchAllClients()
      .then(() => {
        setLastUpdateTimestamp(Date.now());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setClientFromQueryParam = async () => {
    const queryClient = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryClient.client_id as string) {
      await changeSelectedClient(queryClient.client_id as string);
      window.history.pushState({}, "", window.location.pathname);
    }
  };

  useEffect(() => {
    setClientFromQueryParam();
    fetchClientAndRefreshAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: possibly overkill, since we also update these in MainLayout > initialiseClient
  useEffect(() => {
    const actions = getActionsAvailable(access_right_profiles, profile);
    setActionsAvailable(actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_right_profiles, profile]);

  if (loading) {
    return (
      <div style={{ height: "calc(100vh - 20px)" }}>
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "20px",
          }}
        >
          <CustomButton
            type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
            onClick={handleLogout}
          >
            {lang.mainLayout.logOutButton}
          </CustomButton>
        </div>

        <FullScreenLoader />
      </div>
    );
  }

  if (!client?.id) {
    return null;
  }

  return (
    <UpdateMonitor>
      <MainLayout
        // Re-mount when client changes
        key={client.id}
        actionsAvailable={actionsAvailable}
        // TODO: fetch only current client ?
        refreshClient={fetchClientAndRefreshAll}
        route={route}
        fullWidth={isFullWidth(route)}
        cookies={cookies}
      >
        <ClientExpiredOverlay
          key={Date.now()} /** key is a hack to prevent user from removing the overlay in DevTools */
          client={client}
        />

        {!_.includes(["/scanner/", "/map"], window.location.pathname) && (
          <AppBreadcrumbs />
        )}

        <Switch>
          <PrivateRoute path={APP_PATHS.CALENDAR_ROUTE} isAuthenticated={true}>
            <ProtectedComponent
              authenticationFunction={(client) =>
                client.enable_visit_planning === true
              }
              redirectPath={HOME_ROUTE}
            >
              <CalendarViewHandler />
            </ProtectedComponent>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={APP_PATHS.HOME_ROUTE}
            isAuthenticated={true}
          >
            <Suspense fallback={<ContentLoader />}>
              <HomeContainer />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.ENVIRONMENT_ROUTE}
            isAuthenticated={!!actionsAvailable["FETCH_DASHBOARD"]}
          >
            <ProtectedComponent
              authenticationFunction={(client) =>
                isOptimetriksAdminRole(client.profile)
              }
            >
              <Suspense fallback={<TableSkeletonLoader />}>
                <EnvironmentContainer
                  appActions={undefined}
                  table={[]}
                  isCreating={false}
                  isUpdating={false}
                  isDeleting={false}
                  isArchiving={false}
                  isRestoring={false}
                />
              </Suspense>
            </ProtectedComponent>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.DASHBOARDS_ROUTE}
            isAuthenticated={!!actionsAvailable["FETCH_DASHBOARD"]}
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <DashboardContainer
                appActions={undefined}
                isGettingRawData={false}
              />
            </Suspense>
          </PrivateRoute>

          <Route
            exact
            path={USERS_ROUTE}
            render={() => <Redirect to={`${USERS_ROUTE}/mobileuser`} />}
          />

          <PrivateRoute
            exact
            path={APP_PATHS.USERS_ROUTE}
            isAuthenticated={
              actionsAvailable["FETCH_WEB_USER"] ||
              actionsAvailable["FETCH_MOBILE_USER"]
            }
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <UserMgtContainer />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.CLIENTS_ROUTE}
            isAuthenticated={
              !!(
                actionsAvailable["CREATE_CLIENT"] ||
                actionsAvailable["FETCH_CLIENT_SETTINGS"]
              )
            }
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <ClientsContainer />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.TEAMS_ROUTE}
            isAuthenticated={!!actionsAvailable["FETCH_TEAM"]}
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <TeamsContainer />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.WORKFLOWS_ROUTE}
            isAuthenticated={!!actionsAvailable["FETCH_WORKFLOW"]}
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <WorkflowsContainer
                selectedWorkflow={{} as IWorkflow}
                isFetchingActivities={false}
                logger={logger}
              />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.LISTS_ROUTE}
            isAuthenticated={!!actionsAvailable["FETCH_LIST"]}
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <ListsContainer logger={logger} />
            </Suspense>
          </PrivateRoute>

          {client.is_fieldpro_connect_enabled && (
            <>
              {client.erp_config?.type === "PANORAMA" && (
                <PrivateRoute
                  exact
                  path={APP_PATHS.PANORAMA_ROUTE}
                  isAuthenticated={true}
                >
                  <Suspense fallback={<TableSkeletonLoader />}>
                    <PanoramaContainer logger={logger} />
                  </Suspense>
                </PrivateRoute>
              )}
              <PrivateRoute
                exact
                path={APP_PATHS.ERP_ROUTE}
                // TODO: FIX PERMISSIONS
                isAuthenticated={true}
                // isAuthenticated={actionsAvailable["FETCH_ORDER"]}
              >
                <Suspense fallback={<TableSkeletonLoader />}>
                  <ErpContainer logger={logger} />
                </Suspense>
              </PrivateRoute>
            </>
          )}

          <PrivateRoute
            exact
            path={APP_PATHS.PLACES_ROUTE}
            isAuthenticated={true}
          >
            <Suspense fallback={<ContentLoader />}>
              <PlacesContainer />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={APP_PATHS.PICTURES_ROUTE}
            isAuthenticated={true}
          >
            <Suspense fallback={<PictureSkeletonLoader />}>
              <PicturesContainer />
            </Suspense>
          </PrivateRoute>

          <Route
            exact
            path={DOCUMENTS_ROUTE}
            render={() => <Redirect to={`${DOCUMENTS_ROUTE}/clients`} />}
          />

          <PrivateRoute path={DOCUMENTS_ROUTE} isAuthenticated={true}>
            <Suspense fallback={<TableSkeletonLoader />}>
              <DocumentsContainer isDeletingDocuments={false} />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute
            path={`${SCANNER_ROUTE}/:item_id/:item_name`}
            isAuthenticated={true}
          >
            <Suspense fallback={<TableSkeletonLoader />}>
              <ItemQRCodeDialog />
            </Suspense>
          </PrivateRoute>

          <Route component={NotFound} />
        </Switch>
      </MainLayout>
    </UpdateMonitor>
  );
};

export default AuthenticatedApp;
